import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const CookiePolicy = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
  return (<>
    <section>
      <div className="container">
        <div className="row">
            <h1 className="font-38 fw-400 lh-38 text-center my-4 py-5">Cookie Policy</h1>
            <div className="col-12">
            <h6 className="font-16 fw-700 text-333">Introduction and scope</h6>
            <p>Galderma SA and its affiliates (hereinafter “Galderma”, “We”, “Us”) are responsible for the collection and processing of your data as they relate to cookies used in this website. </p>
            <p>This Cookie Policy (hereinafter “Policy”) will explain how this website is using cookies in order to provide clear and relevant information to users on what choices the user has in terms of controlling what happens when he/she accesses our website. </p>
            <p>The Policy uses the term ‘cookies’ to refer to cookies and similar technologies covered by the law (such as, for example, Local Shared Objects – commonly referred to as “flash cookies”, web beacons or bugs, including transparent clear gifs). The gathered information concerns data that could allow to identify web users/visitors by associating and processing them with other data kept by third parties (such as, for example, the IP address, domain and registration numbers of the computers used by people connecting to the website). These data are used and processed only for statistical purposes.</p>
            <p>The Policy is an integral and substantial part of the Privacy Policy and the information notice contained in it and complies with the provisions set out by the applicable national and European data protection and data privacy laws and regulations, including the General Data Protection Regulation (GDPR 2016/679), and the e-Privacy Directive 2002/58/EC, as amended and in force and any other related national and European opinions and guidelines issued by competent Authorities. We recommend, therefore, that you also read our Privacy Policy to know more about Galderma’s general commitment to data protection.</p>
            <p>The Policy applies to all Galderma&nbsp;websites and mobile applications (“Galderma Sites”).</p>
            <h4 className="font-20 fw-500 text-333 my-5">1. WHAT IS A COOKIE?</h4>
            <p>A cookie is a small file, typically consisting of letters and numbers, downloaded on to a device when the user accesses the website. Cookies are text files that are placed on your computer by websites that you visit. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. Cookies contain information that is transferred to your computer’s hard drive. This information might be about you, your preferences or your device and is typically used to make the site work as you expect it. They are used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the website.</p>
            <p>Cookies are useful because they improve the website’s functionality and overall performance and provide the users with a more personalized experience. They also give insights on the use of our website by our visitors and help Us to understand how visitors are using our website and the services provided therein.</p>
            <p>The use of cookies and similar technologies has for some time been commonplace and cookies in particular are important in the provision of many online services. Using such technologies is not, therefore, prohibited by the law but they do require that people are well informed in advance about the use of cookies installed on a website and are given the choice to directly accept all of them or if they wish so, to customize their cookies preferences&nbsp;</p>
            <h4 className="font-20 fw-500 text-333 my-5">2. WHAT ARE THE TYPES OF COOKIES?</h4>
            <ul>
                <li>
                <h6 className="font-16 fw-700 text-333">Session and persistent cookies</h6>
                <p>Cookies can expire at the end of a browser session (from when a user opens the browser window to when they exit the browser) or they can be stored for longer periods of time.</p>
                <p>
                    <span className="text-decoration-underline">Session cookies</span> expire after a browser session. Session cookies allow websites to link the actions of a user during a browser session. They may be used for a variety of purposes such as remembering what a user has put in their shopping basket as they browse around a site. They could also be used for security when a user is accessing internet banking or to facilitate use of webmail. Their use is strictly limited to the purpose of transmitting data (made up of random numbers created by the server) identifying the specific session and necessary to allow a safe and efficient website surfing by avoiding any other computer methods that could potentially compromise the users’ privacy.
                </p>
                <p>
                    <span className="text-decoration-underline">Persistent cookies</span> are stored persistently on a users’ device in between browser sessions. Persistent cookies allow the preferences or actions of the user across a site (or in some cases across different websites) to be remembered. Persistent cookies may be used for a variety of purposes including remembering users’ preferences and choices when using a site.
                </p>
                </li>
                <li>
                <h6 className="font-16 fw-700 text-333">First and third-party cookies </h6>
                <p>Cookies can be classified as ‘first’ or ‘third’ party depending on the website or domain placing the cookie. </p>
                <p>
                    <span className="text-decoration-underline"> First party</span> cookies in basic terms are cookies set by the domain visited by the user – the website displayed in the URL window: i.e., cookies set by Galderma on this website.
                </p>
                <p>
                    <span className="text-decoration-underline"> Third party</span> cookies are cookies that are set by a domain other than the one being visited by the user: i.e., cookies, which are set by companies and web sites other than Galderma, such as Google, Facebook, YouTube etc.
                </p>
                </li>
            </ul>
            <h4 className="font-20 fw-500 text-333 my-5">3. WHAT CATEGORIES OF COOKIES ARE USED ON THIS WEBSITE?</h4>
            <p>We use the following categories of cookies:</p>
            <div id="ot-sdk-cookie-policy"></div>
            <p></p>
            <h4 className="font-20 fw-500 text-333 my-5">4. MANAGE YOUR COOKIE PREFERENCES</h4>
            <p>Some cookies are strictly necessary for the correct functioning of this website and do not require user’s consent, such as those ensuring that the content of a page loads quickly and effectively by distributing the workload across numerous computers or else those providing security.</p>
            <p>Other cookies are still reasonably necessary or important, but they are not strictly essential and, therefore, require user’s consent. For these cookies (i.e. performance and functionality, advertising/targeting), we request your consent (by the “accept all cookies” button in our cookie banner or through your browser cookie settings) before placing them on your device.</p>
            <p>You may access and change your cookie preferences clicking at the bottom left corner of the website.</p>
            <p>Additionally, if you do not wish to give consent or wish to withdraw your consent to any non-essential cookies at any time, you will need to delete, and block or disable cookies via your browser settings. These settings are usually found in the ‘options’ or ‘preferences’ menu of your internet browser. Otherwise, you should use the ‘help’ option in your internet browser for more details or you can click on one of the links below to go directly to the user manual for your browser:</p>
            <ul>
                <li className="mb-2">
                <a className="font-16 fw-500 text-333 text-decoration-none" href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectlocale=en-US&amp;redirectslug=delete-cookies-remove-info-websites-stored">Mozilla Firefox</a>
                </li>
                <li className="mb-2">
                <a className="font-16 fw-500 text-333 text-decoration-none" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%253DDesktop&amp;hl=en">Google Chrome</a>
                </li>
                <li className="mb-2">
                <a className="font-16 fw-500 text-333 text-decoration-none" href="https://support.apple.com/" target="_blank">Safari</a>
                </li>
                <li className="mb-2">
                <a className="font-16 fw-500 text-333 text-decoration-none" href="https://help.opera.com/en/latest/" target="_blank">Opera</a>
                </li>
                <li className="mb-2">
                <a className="font-16 fw-500 text-333 text-decoration-none" href="https://support.microsoft.com/en-in/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank">Internet Explorer</a>
                </li>
            </ul>
            <p> Alternatively, you may wish to visit <a href="http://www.aboutcookies.org">www.aboutcookies.org</a> (link is external), which contains comprehensive information on how to do this on a wide variety of desktop browsers, or the following sites: <a href="http://www.aboutads.info/choices/#completed">http://www.aboutads.info/choices/#completed</a> or <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>
            </p>
            <p>Please note that disabling or blocking some or all of the categories of cookies will affect the functionality of the website and may impact your access and experience with our website and the services we are able to offer. </p>
            <p>Any of your cookie preferences are limited to this website only and not to other third-party owned websites or any other webpages, which may be hyperlinked to this website. For more information on cookies used by those websites, please refer to the specific privacy Policy or cookie policy of those websites.&nbsp;</p>
            <h4 className="font-20 fw-500 text-333 my-5">5. POLICY UPDATE</h4>
            <p>We reserve the right to make any changes and corrections to this Policy. Please refer to this page from time to time to review these and new additional information. We will place updates on Galderma Sites and, where appropriate, we will notify of any changes by email.</p>
            <h4 className="font-20 fw-500 text-333 my-5">6. PRIVACY POLICY</h4>
            <p>For further information about how your data is used (including controller details), please see our <a href="https://www.galderma.com/us/your-privacy">Privacy Policy</a>. </p>
            <p>This Cookie Policy was last updated August 2023.</p>
            </div>
        </div>
      </div>
    </section>
  </>);
};

export default CookiePolicy;
