import companylogo from "../../Assets/images/logo.png";

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-light header-light z-1 py-1">
      <div className="container-fluid">
        <a className="navbar-brand pb-0 ps-xl-4 ps-lg-4 ps-md-4 ps-sm-2 ps-2 pt-0" href="#">
          <img src={companylogo} alt="logo" className="logo" />
        </a>
  </div>
      </nav>
    </div>
  )
}

export default Header