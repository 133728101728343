import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import AboutPage from "../pages/About/AboutPage";
import ContactPage from "../pages/Contact/ContactPage";
import HomePage from "../pages/Home/HomePage";
import { URLConstant } from "./urls";
import ErrorPage from "../pages/Error/ErrorPage";
import PrivacyPolicy from "../pages/PrivacPolicy/privacyPolicy";
import PolicyHeader from "../components/PolicyHeader/PolicyHeaderPage";
import CookiePolicy from "../pages/CookiePolicy/CookiePolicy";

export const RoutesComponent = () => {
  const { urls } = URLConstant;

  const routeComponent = (Component: FC) => {
    return (
      <>
        <Header />
        <Component />
        <Footer />
      </>
    );
  };

  const routePolicyComponent = (Component: FC) => {
    return (
      <>
        <PolicyHeader />
        <Component />        
        <Footer />
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path={urls.homePage} element={routeComponent(HomePage)} />

        <Route path={urls.about} element={routeComponent(AboutPage)} />

        {/* <Route path={urls.products} element={routeComponent(Products)} /> */}

        <Route path={urls.contact} element={routeComponent(ContactPage)} />

        <Route
          path={urls.privacy}
          element={routePolicyComponent(PrivacyPolicy)}
        />
        <Route
          path={urls.cookie}
          element={routePolicyComponent(CookiePolicy)}
        />

        {/* <Route
          path={urls.cookie}
          element={routePolicyComponent(CookiePolicy)}
        /> */}

        {/* <Route
          path={urls.productsDetails}
          element={routeComponent(ProductDetail)}
        /> */}

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};
