import companylogo from "../../Assets/images/logo.png";
import galdermalogo from "../../Assets/images/galderma-logo.png";
const PolicyHeader = () => {
  return (
    <div className="container-fluid bg-white">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center my-3"><img src={galdermalogo} alt="Galderma Logo" title="Galderma Logo" width="320" className="galdermaLogo h-auto w-md-50 mx-auto" /></div>
        </div>
      </div>
    </div>
  );
};

export default PolicyHeader;
