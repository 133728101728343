// import Form from "../../components/Form";
import styles from "./HomePage.module.scss";
import Product from "../../Assets/images/Product-img-new-min.png";
import Product2 from "../../Assets/images/Product-img2.png";
import Product4 from "../../Assets/images/product-image-3.png";
import Product3 from "../../Assets/images/Product Image-2.png";
import LevelUpBg from "../../Assets/images/level-up-bg.png";
import LevelUpTop from "../../Assets/images/level-up-top.png";
import LevelUpProduct from "../../Assets/images/l-product.png";
import twichImg from "../../Assets/images/twitch-con.gif";
import DIFFERIN_V111 from "../../Assets/images/DIFFERIN_V111.png";
import DESKTOP_BANNER from "../../Assets/images/DESKTOP_BANNER.png";
import MOBILE_BANNER from "../../Assets/images/MOBILE_BANNER.jpg";
import dropdown from "../../Assets/images/dropdown.svg";
import downarrow from '../../Assets/images/downarrow.svg';
const desktopVideo = "https://galderma-s3-bucket.s3.amazonaws.com/Test/desktop-banner-bg.mp4";
const MobileVideo = "https://galderma-s3-bucket.s3.amazonaws.com/Test/mobile-banner-bg.mp4";
const s3video = "https://galderma-s3-bucket.s3.amazonaws.com/Test/DIFFERIN_16x9_V3.mp4";

const HomePage = () => {

  return (
    <>
      <main>
        <section className="Videobanner-desktop section-1 d-none d-lg-block h-100">
          <div className="position-relative bg-7e02a9">
            <video
              autoPlay
              playsInline
              muted
              loop
              poster={DESKTOP_BANNER}
              className="w-100 h-100 mt-minus"
            >
              {desktopVideo && (
                <source src={desktopVideo} />
              )}
            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-lg-5 col-sm-12">
                  <div className="pe-3 position-absolute ps-lg-0 ps-xl-5 title top-50 translate-middle-y w-50 pb-lg-4 pb-xl-5">
                    <h1>DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p className="max-w-450px">
                      Unlock clearer skin with Differin’s Rx-strength
                      acne-fighting retinoid.
                    </p>
                    <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://url.uk.m.mimecastprotect.com/s/wKPFCRoy1C8AoOQDUouJsvRyKt?domain=differin.com%22"
                  >
                    <span>Learn More</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Videobanner-mobile section-1 d-block d-lg-none">
          <div className="position-relative  h-100">
            <video
              autoPlay
              muted
              playsInline
              loop
              poster={MOBILE_BANNER}
              className="w-100 h-100 mw-100"
            >
              {MobileVideo && <source src={MobileVideo} />}
            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container ">
              <div className="row">
                <div className="col-12">
                  <div className="position-absolute px-3 py-4 title top-0 py-md-5 my-md-5">
                    <h1>DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p>
                      Unlock clearer skin with Differin’s Rx-strength
                      acne-fighting retinoid.
                    </p>
                    <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://url.uk.m.mimecastprotect.com/s/wKPFCRoy1C8AoOQDUouJsvRyKt?domain=differin.com%22"
                  >
                    <span>Learn More</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="videosection section-2" id="section-2">
          <div className="container">
            <div className="row">
                <h2 className="mx-auto text-uppercase text-center text-uppercase text-white fw-bolder max-w-700px mb-xl-5 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                  See HOW You CAN POWER UP YOUR acne game.
                </h2>
            </div>

            <video
              id="my-video-1"
              className="video-js vjs-default-skin mx-auto"
              controls
              loop
              preload="none"
              poster={DIFFERIN_V111} controlsList="nodownload"
              data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'
            >
              {s3video && (
                <source src={s3video} type="video/mp4" />
              )}
            </video>
          </div>
        </section>

        <section className="levelup-bg section-3">
          {/* <img src={levelup} alt="levelupbg" /> */}
          <div className="container-fluid py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 g-0">
            <div className="align-items-center py-5 pb-md-0 pb-sm-0 pb-0 row m-0" style={{position:"relative"}} >
              <div className="col-12 px-0 levelup-text-sec">
                <div className="title px-xl-5 px-lg-5 px-md-5 px-sm-4 px-4 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-1 mx-1">
                  <h2>LEVEL UP YOUR ACNE IQ.</h2>
                  <p>
                    Tired of grinding for clear pores? Take your skincare game
                    to the next level with Differin Gel. When used every day,
                    Differin Gel can help reduce up to 87% of acne in just 12
                    weeks. 
                  </p>
                  <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://url.uk.m.mimecastprotect.com/s/wKPFCRoy1C8AoOQDUouJsvRyKt?domain=differin.com%22"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>
              <div className="levelup-right-section">
                
                <img
                    style={{position:'absolute'}}
                    className="w-100 h-auto pb-lg-5"
                    src={LevelUpBg}
                    alt="Product"
                />
                  
                <img
                  style={{position:'absolute'}}
                  className="w-100 h-auto pb-lg-5 level-up-product"
                  src={LevelUpProduct}
                  alt="Product"
                />
                 <img
                  style={{position:'absolute'}}
                  className="w-100 h-auto pb-lg-5"
                  src={LevelUpTop}
                  alt="Product"
                />
                
                </div>
            </div>
          </div>
        </section>

        <section className="twitch-con section-4 py-3">
          {/* <img src={levelup} alt="levelupbg" /> */}
          <div className="container py-xl-5 py-lg-5 py-md-5 py-sm-0 py-0">
            <div className="row py-5 align-items-center">
              <div className="col-md-12 col-lg-6 col-sm-12 mb-lg-0 mb-md-4 mb-sm-4 mb-4">
                <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-md-start justify-content-sm-start justify-content-conter align-items-center ps-xl-0 pe-xl-5 ps-lg-3 ps-md-3 px-sm-3 px-3">
                  <img
                    className="w-100 h-auto max-w-420px"
                    width="420"
                    src={twichImg}
                    alt="twichImg"
                  />
                </div>
              </div>

              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="mb-lg-0 mb-md-5 title px-xl-0 px-lg-3 px-md-3 px-sm-3 px-3">
                  <h2 className="mb-0">VISIT US AT TWITCHCON</h2>
                  <p className="mt-3">
                    Enhance your acne care routine at the TwitchCon Differin
                    Arcade! Join interactive events, connect with top streamers,
                    and enjoy a celebration packed with games, meet and greets,
                    live Esports, and more
                  </p>
                  <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://url.uk.m.mimecastprotect.com/s/WK7KCVPDQH1DmZXnhWC9sVljky?domain=twitchcon.com%22"
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="levelup-bg section-5 position-relative pb-xl-0 pb-lg-0 pb-md-5 pb-sm-5 pb-5">
          {/* <img src={levelup} alt="levelupbg" /> */}
          <div className="position-absolute end-0 img-container-products d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none">
            <div>
                <div className="position-absolute downarrow">
                      <img src={downarrow} alt="DownArrow" className="d1 d-block" />
                      <img src={downarrow} alt="DownArrow" className="d2 d-block" />
                      <img src={downarrow} alt="DownArrow" className="d3 d-block" />
                    </div>
                    <img
                      className="img-fluid"
                      src={Product4}
                      alt="Product"
                    />
                </div>
            </div>
          <div className="container-fluid position-relative px-0">
          <div className="row d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block pt-5 mx-0">
              <div className="col-12 d-flex justify-content-end px-0">
                <div className="position-relative max-w-730px">
                    <div className="position-absolute downarrow">
                          <img src={downarrow} alt="DownArrow" className="d1 d-block" />
                          <img src={downarrow} alt="DownArrow" className="d2 d-block" />
                          <img src={downarrow} alt="DownArrow" className="d3 d-block" />
                        </div>
                        <img
                          className="img-fluid"
                          src={Product4}
                          alt="Product"
                        />
                    </div>
                </div>
            </div>
            <div className="row levelup-text-sec">
              <div className="col-12 px-0">
                <div className="title px-xl-5 px-lg-5 px-md-5 px-sm-4 px-4 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-1 mx-1 my-5">
                  <h2>XP BOOST ACTIVATED. FIND DIFFERIN ONLINE. </h2>
                  <p className="max-w-386px">
                    Level up your skincare routine by purchasing Differin online
                    and unlock your greatest achievement yet: Clearer Skin. It’s
                    as easy as a few clicks! No grind necessary.
                  </p>
                  <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://url.uk.m.mimecastprotect.com/s/usQOCG5k8hory4w8HKGHTO?domain=amazon.com"
                  >
                    <span>Buy Differin Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;
