// import { useState } from "react";
import { Link } from "react-router-dom";
import galdermaLogo from "../../Assets/images/galderma-logo.png";

const Footer = () => {
  // const [hiddenBtn, setHiddenBtn] = useState(true);
  return (
    <footer>
      {/* {hiddenBtn && (
        <div className="position-fixed shadow bottom-0 w-100 p-4 py-3 position-fixed bottom-0 w-100 p-4 cookie-settings">
          <button
            onClick={() => setHiddenBtn(false)}
            id="ot-sdk-btn"
            className="cookie-btn ot-sdk-show-settings float-end font-15 fw-700 text-00358f text-decoration-none px-4 py-2"
          >
            Cookie Settings
          </button>
        </div>
      )} */}
      <section className="pt-5 pb-4 bg-white">
        <div className="container">
          <div className="row text-lg-start text-md-start text-sm-center text-center ps-lg-0 ps-xl-5 ms-lg-0 ms-xl-4">
            <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"></div>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
              <p className="font-18 text-333 fw-700 mb-3">
                This site is intended for US audiences only.
              </p>
              <p className="font-14 text-333 fw-400 mb-1">
                © <span>2024</span> Galderma Laboratories, L.P.
              </p>
              <p className="font-14 text-333 fw-400 mb-3">
                All trademarks are the property of their respective owners.
              </p>
              <ul className="list-style-type-none d-flex flex-wrap ps-0 footer-menu max-w-530px">
                <li className="pe-2 me-1">
                  {/* <a className="text-00358f fw-700 text-decoration-none font-15" href="https://www.galderma.com/us/your-privacy"
                                target="_blank">Privacy Policy</a> */}
                  <Link
                    to={"https://www.galderma.com/us/your-privacy"}
                    target="path"
                    // relative="path"
                    className="text-00358f fw-700 text-decoration-none font-15"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="pe-2 me-1">
                <button id="ot-sdk-btn" className="ot-sdk-show-settings cookie-settings-btn"
                  title="Cookies Settings">Cookie Settings</button>
                </li>
                <li className="pe-2 me-1">
                  {/* <a
                    className="text-00358f fw-700 text-decoration-none font-15"
                    href="https://www.galderma.com/us/cookie-policy"
                    target="_blank"
                  >
                    Cookie Policy
                  </a> */}
                  <Link
                    to={"/cookiePolicy"}
                    target="path"
                    className="text-00358f fw-700 text-decoration-none font-15"
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li className="pe-2 me-1">
                  <Link
                    className="text-00358f fw-700 text-decoration-none font-15"
                    to={"https://www.galderma.com/us/your-privacy#CONSUMER_HEALTH_DATA_PRIVACY_POLICY"}
                    target="_blank"
                  >
                    Consumer Health Data Privacy Policy
                  </Link>
                </li>
                <li className="pe-2 me-1">
                  <a
                    className="text-00358f fw-700 text-decoration-none font-15"
                    href="https://www.galderma.com/us/contact-us"
                    target="_blank"
                  >
                    Contact
                  </a>
                </li>
                {/* <li className="pe-2 me-1">
                  <a
                    className="text-00358f fw-700 text-decoration-none font-15"
                    href="https://differin.com/site-map"
                    target="_blank"
                  >
                    Sitemap
                  </a>
                </li> */}
                <li className="pe-2 me-1">
                  <a
                    className="text-00358f fw-700 text-decoration-none font-15"
                    href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc"
                    target="_blank"
                  >
                    Your Privacy Choices
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
              <img
                src={galdermaLogo}
                className="galdermaLogo h-auto w-md-50"
                width="200"
                alt="Galderma Logo"
                title="Galderma Logo"
              />
            </div>
          </div>
          <div className="text-center fw-400 text-14 text-333 policy-row py-3">
            NEW: Your use of this website is subject to our
            <a
              className="text-00358f text-decoration-none policy"
              type="button"
              aria-label="Read update privacy policy"
              href="https://www.galderma.com/us/your-data"
              target="_blank"
            >
              <span>&nbsp;Updated Privacy Policy.</span>&nbsp;&nbsp;
              <span className="no-break learn-link">
                Click here to learn more.
              </span>
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
