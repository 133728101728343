import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { RoutesComponent } from "./routes";

function App() {
  return (
    <Router>
      <RoutesComponent />
    </Router>
  );
}

export default App;
