import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const PrivacyPolicy = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
  return (<>
    <section className="">
      <div className="container">
        <div className="row">
            <div className="col-12">
                    <h1 className="font-38 fw-400 lh-38 text-center my-4 py-5">Your Privacy</h1>
                    <h3 className="font-20 fw-500 lh-38 text-left my-4" id="US_PRIVACY_POLICY">
                        GALDERMA U.S. PRIVACY POLICY<br/>
                    </h3>
                    <h3 className="font-20 fw-500 lh-38 text-left my-4">
                        Last Updated: July 1, 2024
                    </h3>
                    <p>Galderma Laboratories, L.P. (hereinafter referred to as “<span className="fw-700">Galderma</span>,” “we,” “our,” or “us”) is committed to safeguarding your privacy. We ask that you carefully review this Privacy Policy, as it applies to the information Galderma collects from and about you, whether online or offline, wherever this Privacy Policy appears or is referenced. This includes information we collect when you: (a) visit our websites (“Sites”); (b) use our mobile applications (“Apps,”); (c) register for and participate in the ASPIRE Galderma Rewards Healthcare Professional and Consumer Loyalty Programs (the “ASPIRE Program”; <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://www.aspirepracticerewards.com ">https://www.aspirepracticerewards.com</a></span> , <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://www.aspirerewards.com">https://www.aspirerewards.com</a></span> ) or another special offer (collectively, “Offers”); or (d) otherwise choose to contact us. By using or accessing the Sites, Apps (collectively, “Platform”), Offers and our related services (collectively, the “Services”), you agree to the collection, use, and disclosure of your information, as described in this Privacy Policy. If you do not agree, please do not access or use the Services. </p>
                    <p><em>California Notice of Collection of Personal Information</em>: Galderma collects personal information listed below under “<a  className="text-dark text-decoration-none" href="#INFORMATION_WE_COLLECT"><span className="fw-700">Information We Collect</span></a>” for the purposes described below under “<span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_USE">Information Use</a></span>.” If you are a California resident, click <span className="fw-700"><a  className="text-dark text-decoration-none" href="#YOUR_CALIFORNIA_PRIVACY">Your California Privacy Rights</a></span> to learn more about your California privacy rights, including how to request that we not “sell” or “share” your personal information by clicking <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc">Your Privacy Choices</a></span>.</p>
                    <p><em>Galderma Consumer Health Data Privacy Policy: </em>Click <span className="fw-700"><a  className="text-dark text-decoration-none" href="#CONSUMER_HEALTH_DATA_PRIVACY_POLICY">here</a></span> to view the Galderma Consumer Health Data Privacy Policy.<em>  </em></p>
                    <ol className="privacy-policy">
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="INFORMATION_WE_COLLECT">INFORMATION WE COLLECT</h3>
                          <p><span className="fw-700">A. Types of Information We Collect  </span></p>
                          <p>Galderma (and the vendors and other parties we use to perform services on our behalf) collects information from or about you when you use the Services, including when you choose to share information with us. This may include information such as: your contact and biographical information (e.g., name, address, email address, username, telephone or mobile number, date of birth, demographic information, or gender); professional information (e.g. information found in your curriculum vitae or resume, information about your current and past interactions with Galderma and about your participation in events, conferences and scientific studies); user-generated content (e.g., ratings, reviews, comments, video, audio containing your image or voice, or call history); customer records (e.g. purchase and usage history, and preferences); financial account information (e.g., to process payments); geo-location information; unique user identifiers (e.g. VoIP identifier, username and password, instant messaging ID, video chat identifier, social networking login information, or third-party platform login information); information you submit, provide, or post (e.g. feedback and other information you provide when you contact us or use our online chat features); inferences; medical, allergy, biometric, or other health and aesthetic related information (e.g. treatment history, loyalty program history, treatment date, treatment type, treating healthcare professional). If you apply for a job at Galderma, we will ask for additional personal information, such as your social security number, government-issued identification number, and job history. Some of the information we collect may be considered sensitive information in certain jurisdictions, including, for example, username and password; precise geolocation data; certain demographic information like race and ethnicity; medical, allergy, biometric, and other health and aesthetic related information.</p>
                          <p><span className="fw-700">B. Information Passively Collected</span></p>
                          <p>Additionally, whenever you visit or interact with the Platform, Galderma or its vendors may use a variety of technologies such as cookies, web beacons, pixel tags, log files, local shared objects (Flash cookies), HTML5 cookies, or other technologies to passively collect information about your online and digital activity. We and our vendors may collect the following information about you:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700"><em>Computer or Device Information</em></span>. We and our vendors may collect your Internet Protocol (“IP”) address or other unique identifiers or information from the computer, mobile device, tablet, or other device you use to access the Platform, including but not limited to your br/owser type, device type, operating system, software version, phone model, phone operating system, carrier information, device ID, advertising ID, and the domain name from which you accessed the Services. </li>
                              <li><span className="fw-700"><em>Usage Information</em></span>. We and our vendors may collect information about your use of the Platform, including the date and time you visit the Platform, the areas or pages of the Platform that you visit, the amount of time you spend viewing or using the Platform, content entry, mouse movements, screen captures, the number of times you return to the Platform, other click-stream or usage data for Platform, emails that you open, forward or click-through to our Platform, and other sites that you may visit. We and our vendors may also embed technologies (which may include, for example, web beacons, tags, and pixels) in the emails and other communications we or our vendors send to you to help us understand how you interact with those messages, such as whether and when you opened a message or clicked on any content within the message.</li>
                              <li><span className="fw-700"><em>Use of Analytic Technologies</em></span>. We and our vendors may use analytics vendors and tracking tools to better understand who is using the Platform, how people are using the Platform, how to improve the effectiveness of the Platform and related content, and to help us or our vendors serve more targeted advertising to you across the Internet. These tools may combine information from your interaction with the Platform with information collected from other sources.</li>
                          </ul>
                          <p>To learn more about your choices relating to the collection and use of your information for certain advertising purposes, please see the “<span className="fw-700"><a  className="text-dark text-decoration-none" href="#YOUR_CHOICES_AND">Your Choices and Opt-Outs</a></span>” section below.</p>
                          <p><span className="fw-700">C. Information Collected From Other Sources</span></p>
                          <p>We may acquire information about you from other sources, such as healthcare professionals, social networks, or select vendors (e.g., business partners, service providers, analytics vendors, and advertising networks). We may use this information as further described in <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_USE">Section 2</a></span>, including to help us maintain the accuracy of the information we collect, prevent fraud, personalize your experience with the Platform, target our communications so that we can inform you of our or third-party products, services or other offers that may be of interest to you, measure ad quality and responses, administer the ASPIRE Program or other Offers, and for other business purposes. We may also combine information about you that we receive from other parties with other information about you that we collect when you use the Platform or that you choose to share with us.</p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="INFORMATION_USE">INFORMATION USE</h3>
                          <p><span className="fw-700">A. Use by or for Galderma</span></p>
                          <p>Galderma may use the information we collect from and about you for the following business purposes:</p>
                          <ul className="list-style-type-disc">
                              <li>To provide you with and administer the Services;</li>
                              <li>To fulfill and comply with our contractual and other obligations;</li>
                              <li>To provide support and respond to your inquiries, fulfill orders, contact and communicate with you when necessary;</li>
                              <li>To review the usage and operations of our Platform, including the ASPIRE Program, develop new products or services, improve the Services, and conduct analyses to enhance or improve our content, products, and services;</li>
                              <li>For security, safety, compliance, and due diligence purposes;</li>
                              <li>To administer the ASPIRE Program and other Offers; and</li>
                              <li>To use and disclose your payment or banking information only to process payments and prevent transactional fraud or otherwise to comply with applicable law.</li>
                          </ul>
                          <p>Galderma may use the information we collect from and about you for the following commercial purposes:</p>
                          <ul className="list-style-type-disc">
                              <li>To provide you with customized content or targeted offers;</li>
                              <li>To provide you with information, newsletters and promotional materials from Galderma and, or on behalf of, our marketing partners and affiliates; and</li>
                              <li>To use your data in an aggregated non-specific format for analytical and demographic purposes.</li>
                          </ul>
                          <p>Galderma may use information you provide to us for the following additional purposes:</p>
                          <ul className="list-style-type-disc">
                              <li>For other purposes disclosed at the time you provide your information or otherwise with your consent.</li>
                          </ul>
                          <p>Additionally, if you use the Platform to connect with third-party services, you may authorize us to use information from and about you, on your behalf, to interact with these third-party services based on your requests.</p>
                          <p><span className="fw-700">B. How We Use Your Information for Interest-Based Advertising</span></p>
                          <p>We may use other parties to measure and target advertisements for us about our products and services, and those of our marketing partners and affiliates, that are tailored to your online interests. We allow these companies to use cookies, web beacons, pixel tags, and similar technologies to collect certain data and other information about your online and digital activity. This information is used to display advertisements for Galderma on the Platform or across other websites, mobile applications, social media, or online services that you use.</p>
                          <p>For information on your choices with respect to interest-based ads, see the “<a  className="text-dark text-decoration-none" href="#YOUR_CHOICES_AND"><span className="fw-700">Interest-Based Advertising Choices</span></a>” section below.</p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="INFORMATION_WE_SHARE">INFORMATION WE SHARE WITH OTHERS</h3>
                          <p>We may share your personal information with the following:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700"><em>Vendors</em></span>: We may share your information with vendors that provide business, professional, or technical support functions for us, help us operate our business and the Platform, or administer activities on our behalf.</li>
                              <li><span className="fw-700"><em>Healthcare Professionals:</em></span> To administer the ASPIRE Program, we may share your information (including your aesthetic information) with your healthcare professional.</li>
                              <li><span className="fw-700"><em>Business Partners and Affiliates:</em></span> We may share your information with our select partners, affiliates, your healthcare professional(s) and other parties for their business, operational, promotional, and marketing purposes. These entities’ use of such information is subject to their respective privacy policies. To learn more about your advertising choices relating to the collection and use of your information, please see the “<a  className="text-dark text-decoration-none" href="#YOUR_CHOICES_AND"><span className="fw-700">Your Choices and Opt-Outs</span></a>” section below.</li>
                              <li><span className="fw-700"><em>Legal Matters; Safety</em></span>: We may access and disclose your information to comply with law, or to respond to subpoenas, judicial processes, or government requests and investigations, or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law. We may disclose your information to protect the security of our Services, Platform, servers, network systems, databases, and business. We also may disclose your information as necessary, if we believe that there has been a violation of our Terms of Use, any other legal document or contract related to our services, or the rights of any third party.</li>
                              <li><span className="fw-700"><em>Sale or Transfer of Business or Assets</em></span>: We may sell or purchase assets during the normal course of our business. If another entity acquires or intends to acquire us or any of our assets, information we have collected about you may be transferred to such entity. In addition, if any bankruptcy or reorganization proceeding is br/ought by or against us, such information may be considered an asset of ours and may be sold or transferred to other parties. </li>
                          </ul>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="YOUR_CHOICES_AND">YOUR CHOICES AND OPT-OUTS</h3>
                          <p><span className="fw-700">A. Use of Tracking Cookies</span></p>
                          <p>Certain parts of our Sites and Apps require cookies. You may adjust your device or Internet br/owser settings to limit certain tracking or to decline cookies, but by doing so, you may not be able to use certain features of the Services or take full advantage of all of our offerings. Please refer to your device’s settings or your Internet br/owser’s “Help” section for more information on how to delete and/or disable your device or br/owser from receiving certain cookies or controlling your tracking preferences.</p>
                          <p>We use Google Analytics to better understand how our users interact with our websites. You can learn about Google Analytics’ currently available opt-outs at <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a></span>.</p>
                          <p>Our system may not recognize Do Not Track requests or headers from some or all br/owsers. We may use cookies or other technologies to deliver more relevant advertising and to link data collected across other computers or devices that you may use. </p>
                          <p><span className="fw-700">B. Interest-Based Advertising Choices</span></p>
                          <ul className="list-style-type-disc">
                                <li>We and third-party advertising networks, social media companies, and other services may collect information about your use of the Platform so that they or we may display interest-based advertising using information gathered about you over time across multiple websites or other platforms. To do this, we and our vendors use several tracking tools, such as br/owser cookies, web beacons, and other technologies. To learn more about such interest-based advertising, and to opt out of such collection and use for interest-based advertising by the Digital Advertising Alliance (DAA) participating companies, please visit <span className="fw-700"><a  className="text-dark text-decoration-none" href="http://optout.aboutads.info">http://optout.aboutads.info</a></span>. </li>
                                <li>To opt-out of the use of your mobile device ID for targeted advertising, please see <span className="fw-700"><a  className="text-dark text-decoration-none" href="http://www.aboutads.info/appchoices">http://www.aboutads.info/appchoices</a></span>. </li>
                          </ul>
                          <p>Even if you opt out of cookie-based targeted advertising, you still may receive advertising that is not customized based on your usage information or interests.</p>
                          <p>Please note that options you select and choices you make with regard to cookies and tracking tools are br/owser- and device-specific. This means, for instance, that you need to make your selection on each br/owser and each device you use to access the Platform. If you clear your cookies or your br/owser’s cache, you will need to set your preferences again. If you block cookies, certain functionality of the Platform may become impaired or disabled.</p>
                          <p><span className="fw-700">C. Unsubscribing from our Marketing Communications</span></p>
                          <p>We provide you with the opportunity to opt-out of certain email marketing communications. To do so, you may: </p>
                          <ul className="list-style-type-disc">
                                <li>Send us an email at <span className="fw-700"><a  className="text-dark text-decoration-none" href="mailto:unsubscribeUS@galderma.com">unsubscribeUS@galderma.com</a></span> ;</li>
                                <li>Contact us by mail at the address below in the “<span className="fw-700"><a  className="text-dark text-decoration-none" href="#HOW_TO_CONTACT">How to Contact Us</a></span>” section; or</li>
                                <li>Unsubscribe from our email communications following the instructions contained within the email at any time.</li>
                          </ul>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="YOUR_CALIFORNIA_PRIVACY">YOUR CALIFORNIA PRIVACY RIGHTS</h3>
                          <p><span className="fw-700">A. Shine the Light Request </span></p>
                          <p>If you reside in California, you have the right to ask us one time each year if we have disclosed personal information with third parties for their direct marketing purposes. To make a request, please submit your request in writing by email at <span className="fw-700"><a  className="text-dark text-decoration-none" href="mailto:usprivacy@galderma.com">usprivacy@galderma.com</a></span> or by mail to:</p>
                          <p>Galderma Laboratories, L.P.</p>
                          <p>2001 Ross Avenue, Suite 1600</p>
                          <p>Dallas, TX 75201</p>
                          <p>Attention: Chief Privacy Officer</p>
                          <p>Please include the statement “California Shine the Light” in the subject line of your e-mail or in the header of your mailed letter.</p>
                          <p><span className="fw-700">B. Your California Rights</span></p>
                          <p>If you are a California resident, you may also take advantage of the following rights:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700">Right to Know</span>: You may request that we disclose to you the categories and/or the specific pieces of personal information that we collect about you, the categories of sources from which we collected the personal information, the categories of personal information we sold or disclosed, our business or commercial purpose for collecting, selling or sharing the personal information, and the categories of third parties with whom we disclosed the personal information.</li>
                              <li><span className="fw-700">Right to Correct</span>: You may request that we correct inaccurate personal information that we maintain about you, subject to certain exceptions.</li>
                              <li><span className="fw-700">Right to Delete:</span> Subject to certain exceptions, you may request that we delete personal information that we have collected from you. Note that there are some reasons we will not be able to fully address your request, such as if we need to complete a transaction for you, to detect and protect against fraudulent and illegal activity, or to comply with a legal obligation.</li>
                              <li><span className="fw-700">Right to Opt Out of the Sale or Sharing of Personal Information:</span> As described in this Privacy Policy, we disclose personal information to certain third parties to enable personalized ads on our Sites, Apps and other websites. You may request to opt out of these “sales” or “shares” (as defined by California law) of personal information at any time by clicking the <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/ webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc">Your Privacy Choices</a></span> link, or contacting us using the contact details provided below. Please note that if you would like to opt out of sales or sharing through certain cookie-based tracking for advertising purposes, you may do so by separately clicking the cookie icon at the bottom left corner of the Site you visit, or the “Cookie Settings” footer link at the bottom of the Site you visit, and setting your preferences, or br/oadcasting an opt-out preference signal recognized by Galderma, such as a Global Privacy Control signal, on br/owsers or br/owser extensions that support such a signal. You can also visit <span className="fw-700"><a  className="text-dark text-decoration-none" href="www.aboutads.info/choices">www.aboutads.info/choices</a></span> and <a  className="text-dark text-decoration-none" href="https://optout.privacyrights.info"><span className="fw-700">https://optout.privacyrights.info</span></a> to opt out of certain uses of cookies for advertising purposes. Your cookie-related selections are specific to the device, website, and br/owser you are using. Your selections are deleted whenever you clear your cookies or br/owser’s cache.</li>
                              <li><span className="fw-700">Right to Limit the Use/Disclosure of Sensitive Personal Information:</span> You may request that we limit the use or disclosure of your sensitive personal information, subject to certain exceptions, by clicking the <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/ webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc">Your Privacy Choices</a></span> link.</li>
                          </ul>
                          <p>To make the requests described in this Section, please contact us using our <a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc"><span className="fw-700">webform</span></a>, or contact us by phone at 1-866-735-4137 extension 3, or by email or mail at the addresses in the “<span className="fw-700"><a  className="text-dark text-decoration-none" href="#HOW_TO_CONTACT">How to Contact Us</a></span>” section below. If you choose to email us, you must put the statement “Your California Privacy Rights” in the subject field. We may take steps to verify your identity before responding to your request by asking you a series of questions about your previous interactions with us.</p>
                          <p>If you use an authorized agent to make a request, please have the agent complete the <a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc"><span className="fw-700">webform</span></a>. We may require the authorized agent to provide proof that you gave the agent signed permission to submit the request. We may also require you to verify your identity directly with us prior to fulfilling the authorized agent request.</p>
                          <p>We will not discriminate against you for exercising any of the foregoing rights.</p>
                          <p>From time to time, we may offer financial incentives in connection with the collection or use of personal information related to our loyalty programs. The offers and incentives made available through them are generally related to the value of the relationships that we have with the individuals who participate. The material terms of the financial incentive will generally be presented to you at the time you sign up. You may withdraw from any financial incentive at any time by emailing us at <span className="fw-700"><a  className="text-dark text-decoration-none" href="mailto:usprivacy@galderma.com">usprivacy@galderma.com</a></span> or by calling us at 1-866-735-4137 extension 3.</p>
                          <p><span className="fw-700">C. Additional Information</span></p>
                          <p>In addition to the information provided elsewhere in this Privacy Policy, the following describes the categories of personal information that Galderma collects, discloses, and uses for the business and commercial purposes listed above under Section 2, <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_USE">"Information Use</a></span>”, including in the previous 12 months:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700">Identifiers/Biographical Information</span>: These include your name, postal and email address, phone number, unique identifiers, and date of birth.</li>
                              <li><span className="fw-700">Commercial information</span>: This includes your purchase and usage history, and preferences.</li>
                              <li><span className="fw-700">Internet/Electronic Activity:</span> This includes your IP address, web/app br/owsing and search history related to our Platform, and information regarding your interaction(s) with the Platform.</li>
                              <li><span className="fw-700">Profile Inferences</span>: This includes the inferences we draw from your information and web activity to create a personalized profile so we can better identify goods and services that may be of interest.</li>
                              <li><span className="fw-700">Financial Information:</span> This includes your bank card and credit card information used to process payments.</li>
                              <li><span className="fw-700">Customer Records/Medical/Health Insurance/Aesthetic Information:</span> This includes treatment history, loyalty program history, treatment date, treatment type, and treating healthcare professional.</li>
                              <li><span className="fw-700">Characteristics of protected classNameifications under California or federal law:</span> This includes demographic information such as age or gender.</li>
                              <li><span className="fw-700">Geolocation Data:</span> This includes mailing address, and precise geo-location information.</li>
                              <li><span className="fw-700">Audio/Visual Information</span>: This includes audio recordings during customer service calls; photographs, scans and other images you submit to us.</li>
                              <li><span className="fw-700">Professional/Employment Information</span>: If you apply for a job, this may include your work experience or certifications.</li>
                              <li><span className="fw-700">Sensitive Personal Information</span>: This includes username and password; precise geolocation data; certain demographic information like race and ethnicity; medical, allergy, biometric, and other health and aesthetic related information.</li>
                          </ul>
                          <p>We collect these categories of personal information from the following categories of sources as described in more detail under Section 1, <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_WE_COLLECT">"Information We Collect</a></span>”: passive collection of information about your online activity, analytics vendors and tools, healthcare professionals, service providers, data br/okers, advertising networks, and other select parties.</p>
                          <p>Galderma may disclose each of the categories of personal information above to the extent permitted by applicable law to the categories of third parties described above in Section 3, <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_WE_SHARE">"Information We Share With Others</a></span>.” </p>
                          <p>Galderma may “sell” or “share” (as such terms are defined in applicable California law) categories of personal information, including: Identifiers/Biographical Information, Internet/Electronic Activity, and Profile Inferences. We may sell or share these categories of personal information with the categories of third parties described in <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_WE_SHARE">Section 3</a></span> above, including advertising networks and data analytics providers. We do so for the commercial or business purposes described in <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_USE">Section 2</a></span>. Galderma does not knowingly sell or share the personal information of consumers under the age of 16 years.</p>
                          <p>Galderma will retain and use personal information for as long as necessary for the fulfillment of the purposes described in this Privacy Policy, including, for example, to fulfill our obligations, legal compliance, dispute resolution, contract enforcement, backup, archival, and other business operations purposes. </p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">YOUR COLORADO, CONNECTICUT, MONTANA, OREGON, TEXAS, UTAH AND VIRGINIA PRIVACY RIGHTS</h3>
                          <p>If you are a resident of Colorado, Connecticut, Montana, Oregon, Texas, Utah or Virginia, you may make certain requests with respect to your personal information in accordance with applicable law, including:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700">Access/Confirmation: </span>You may request to confirm whether we process your personal information. Subject to certain exceptions, you may also request a copy of your personal information you provided to us. Oregon residents may also request a list of third parties (as defined in Oregon law) to whom we have disclosed personal information.</li>
                              <li><span className="fw-700">Deletion: </span>You may request that we delete personal information we have from or about you, subject to certain exceptions.</li>
                              <li><span className="fw-700">Correction: </span>You may request that we correct inaccurate personal data we maintain about you, subject to certain exceptions under applicable law.</li>
                              <li><span className="fw-700">Opt-Out of Sale: </span>You may request to opt out of the sale (as defined in applicable state law) of your personal information.</li>
                              <li><span className="fw-700">Opt-Out of Targeted Advertising: </span>We may use personal information obtained from or in connection with your activities across unaffiliated sites to send more relevant advertising to you. You may request that we stop using your personal information for such targeted advertising. </li>
                          </ul>
                          <p>To exercise your Colorado, Connecticut, Montana, Oregon, Texas, Utah or Virginia privacy rights, please contact us using our <a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc"><span className="fw-700">webform</span></a>, or contact us by phone at 1-866-735-4137 extension 3, or by email or mail at the addresses in the “<span className="fw-700"><a  className="text-dark text-decoration-none" href="#HOW_TO_CONTACT">How to Contact Us</a></span>” section below.  If you choose to email us, you must put the statement “Your Privacy Rights” in the subject field. We may take steps to verify your identity before responding to your request by asking you a series of questions about your previous interactions with us.</p>
                          <p>We may “sell” or process for targeted advertising purposes certain of the categories of personal information identified in <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_WE_COLLECT">Section 1</a></span>. We may “sell” these categories of personal information to or with the categories of third parties described in <span className="fw-700"><a  className="text-dark text-decoration-none" href="#INFORMATION_WE_SHARE">Section 3</a></span> of this Privacy Policy. For purposes of this Section, “sell” means the sale of your personal information to a third party for monetary or other valuable consideration, subject to certain exceptions set forth in applicable law. Please note that if you would like to opt out of sales or targeted advertising through certain cookie-based tracking for advertising purposes, you may do so by separately clicking the cookie icon at the bottom left corner of the Site you visit, or the “Cookie Settings” footer link at the bottom of the Site you visit, and setting your preferences, or br/oadcasting an opt-out preference signal recognized by Galderma, such as a Global Privacy Control signal, on br/owsers or br/owser extensions that support such a signal. Please keep in mind that your settings are specific to the Site you were on when you set your preferences. You can also visit <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://aboutads.info/choices">www.aboutads.info/choices</a></span> and <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://optout.privacyrights.info">https://optout.privacyrights.info</a></span> to opt out of certain uses of cookies for advertising purposes. Your cookie-related selections are specific to the device, website, and br/owser you are using. Your selections are deleted whenever you clear your cookies or br/owser’s cache.</p>
                          <p>If you are a resident of Colorado, Connecticut, Montana, Oregon, or Texas, you may designate an agent to submit an opt-out of sale and opt-out of targeted advertising request on your behalf. Proof to verify your identity and the authorized agent’s authority to submit requests on your behalf is required, or we may deny the request. We use commercially reasonable efforts to authenticate your identity and the authorized agent’s authority through the written documentation provided in connection with the opt-out request in accordance with this Privacy Policy.</p>
                          <p>We may deny certain requests and will explain why your request was denied and how to appeal our decision, as required by applicable law. After receiving our explanation, if you would like to appeal our decision and are a resident of Colorado, Connecticut, Montana, Oregon, Texas, or Virginia, you may do so as further detailed in our communication back to you. For questions, you may also contact us by calling or emailing us at the contact information below.</p>
                          <p>Note that for purposes of these rights under this Section 6, personal information does not include information about job applicants, employees and other of our personnel or information about employees and other representatives of third-party entities we may interact with.</p>
                          <p>From time to time, we or our vendors on our behalf may collect personal information in connection with a promotion, offer, program, discount or other benefit, including the ASPIRE Program. See <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://www.aspirerewards.com/terms">https://www.aspirerewards.com/terms</a></span> for ASPIRE Program terms and benefits. </p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">EXTERNAL LINKS</h3>
                          <p>The Platform may have links to third-party websites, which may have privacy policies that differ from our own. We are not responsible for the practices of such sites.</p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">PUBLICATION OF USER SUBMISSIONS</h3>
                          <p>Any information you may disclose on our Platform (e.g., ratings and reviews), in blogs, on message boards, in chat rooms, on social media, or in other public areas becomes public information. Please exercise caution when disclosing personal information in these public areas, including personal health information.</p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">CHILDREN’S PRIVACY</h3>
                          <p>The Platform is meant for adults. We do not direct the Services or Platform to, nor do we knowingly collect any personal information from, children under the age of 16. </p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">REVISIONS TO THIS PRIVACY POLICY</h3>
                          <p>We reserve the right, at our sole discretion, to change, modify, add, remove, or otherwise revise portions of this Privacy Policy at any time. When we do, we will post the change(s) on the Platform. Your continued use of the Services or Platform following the posting of changes to these terms means you accept these changes. </p>
                        </li>
                        <li>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="HOW_TO_CONTACT">HOW TO CONTACT US</h3>
                          <p>If you have any questions or concerns about this Privacy Policy or the practices described herein, you may contact us at <span className="fw-700"><a  className="text-dark text-decoration-none" href="mailto:usprivacy@galderma.com">usprivacy@galderma.com</a> </span>or by mail to:</p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="CONSUMER_HEALTH_DATA_PRIVACY_POLICY"> </h3>
                          <p>Galderma Laboratories, L.P.</p>
                          <p>2001 Ross Avenue, Suite 1600</p>
                          <p>Dallas, TX 75201</p>
                          <p>Attention: Chief Privacy Officer</p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">GALDERMA CONSUMER HEALTH DATA PRIVACY POLICY </h3>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">Effective: March 31, 2024</h3>
                          <p>Galderma Laboratories, L.P. (hereinafter referred to as “Galderma,” “we,” “our,” or “us”) is committed to safeguarding your privacy. We ask that you carefully review this Consumer Health Data Privacy Policy (this “Policy”). This Policy applies to the “consumer health data” (as that term is defined by applicable laws in Nevada and Washington) that Galderma collects, whether online or offline, wherever this Policy appears or is referenced.  By providing us with consumer health data or using or accessing our websites (“Sites”), mobile applications (“Apps”) (Sites and Apps, collectively, the “Platform”), the ASPIRE Galderma Rewards Consumer Loyalty Program (the “ASPIRE Program”), our special offers (“Offers”) and our related services (collectively, the “Services”), you agree to the collection, use, and disclosure of your consumer health data, as described in this Policy.  If you do not agree, please do not provide us with consumer health data and do not access or use the Services.</p>
                          <p>This Policy supplements the Galderma U.S. Privacy Policy and applies solely to consumer health data, as described above.  As used herein, references to Galderma, we, our, and us include Alastin Skincare, Inc. </p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">CONSUMER HEALTH DATA WE MAY COLLECT</h3>
                          <p>We (and the vendors and other parties we use to perform services on our behalf) may collect the following categories of consumer health data; the categories of consumer health data we collect from or about you specifically (if any) will depend on the context of your interactions with Galderma and the choices you make: </p>
                          <ul className="list-style-type-disc">
                              <li>Information about a consumer’s health conditions, symptoms, status, diagnoses, testing, or treatments;</li>
                              <li>Measurements of bodily functions, vital signs, or characteristics; </li>
                              <li>Biometric information; </li>
                              <li>Genetic information;</li>
                              <li>Inferences about you;</li>
                              <li>Information that identifies a consumer seeking health care services; and </li>
                              <li>Other information that identifies your past or present health status</li>
                          </ul>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">SOURCES OF CONSUMER HEALTH DATA</h3>
                          <p>We may collect consumer health data directly from you. For example, we may collect consumer health data as part of information you submit, provide, or post (for example, feedback and other information you provide when you contact us or use our online chat features). Additionally, whenever you visit or interact with the Platform, Galderma or its vendors may use a variety of technologies such as cookies, web beacons, pixel tags, log files, local shared objects (Flash cookies), HTML5 cookies, or other technologies to passively collect information about your online and digital activity. For more information about how we use information that is collected passively, please see our <span className="fw-700"><a  className="text-dark text-decoration-none" href="#US_PRIVACY_POLICY">U.S. Privacy Policy</a></span>. Finally, we may acquire consumer health data about you from other sources, such as healthcare professionals, social networks, or select vendors (for example, business partners, service providers, analytics vendors, and advertising networks). We may also combine information about you that we receive from other parties with other information about you that we collect when you use the Platform or that you choose to share with us, and this combined information may in some instances be considered consumer health data.</p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">PURPOSES FOR WHICH WE MAY COLLECT AND USE CONSUMER HEALTH DATA</h3>
                          <p>We may collect and use consumer health data (a) to provide you with and administer the Services; (b) to fulfill and comply with our contractual and other obligations; (c) to provide support and respond to your inquiries, fulfill orders, contact and communicate with you when necessary; (d) to review the usage and operations of our Platform, including the ASPIRE Program, develop new products or services, improve the Services, and conduct research and analyses to enhance or improve our content, products, and services; (e) to administer the ASPIRE Program and other Offers; (f) for security, safety, compliance, and due diligence purposes; (g) to provide you with customized content or targeted offers; (h) to provide you with information, newsletters and promotional materials from Galderma and, or on behalf of, our marketing partners and affiliates; and (i) to create data in an aggregated format that may be used for analytical and demographic purposes.</p>
                          <p>Additionally, we may use consumer health data for other purposes for which we give you choices and/or obtain your consent or authorization as required by law. See the “<a  className="text-dark text-decoration-none" href="#YOUR_PRIVACY_RIGHTS"><span className="fw-700">Your Privacy Rights</span></a>” and “<a  className="text-dark text-decoration-none" href="#EXCERCISING_YOUR_RIGHTS"><span className="fw-700">Exercising Your Rights</span></a>” sections below for more details on the controls and choices you may have.</p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">HOW WE MAY SHARE CONSUMER HEALTH DATA</h3>
                          <p>We may share your consumer health data for the purposes described above. Additionally, we may share personal data, including consumer health data, with your consent or as reasonably necessary to complete any transaction or provide any product or service you have requested or authorized, as described above. We may share your consumer health data with your health care professionals and our business partners. Additionally, Galderma Laboratories, L.P. and Alastin Skincare, Inc. may share consumer health data with each other. </p>
                          <p>We may also disclose your consumer health data (a) to vendors that provide business, professional, or technical support functions for us, help us operate our business and the Platform, or administer activities on our behalf; (b) to comply with law, or to respond to subpoenas, judicial processes, or government requests and investigations, or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law; (c) to protect the security of our Services, Platform, servers, network systems, databases, and business; (d) as necessary, if we believe that there has been a violation of our Terms of Use, any other legal document or contract related to our services, or the rights of any third party; and (e) to another entity that acquires or intends to acquire us or any of our assets in connection with a sale or bankruptcy or reorganization proceeding. </p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="YOUR_PRIVACY_RIGHTS">YOUR PRIVACY RIGHTS</h3>
                          <p>Subject to certain legal limitations and exceptions, you may be able to exercise some or all of the following rights:</p>
                          <ul className="list-style-type-disc">
                              <li><span className="fw-700">Confirmation and Access:</span> You may request to confirm whether we collect, share, or sell consumer health data about you. You may also request to access such consumer health data, including a list of all third parties and affiliates with whom Galderma has shared or sold the consumer health data and an active email address or other online mechanism that you may use to contact these third parties.</li>
                              <li><span className="fw-700">Deletion:</span> You may request that we delete any of your consumer health data collected from you and retained by us. </li>
                              <li><span className="fw-700">Withdraw Consent:</span> You may request to withdraw consent to Galderma's collection and sharing of consumer health data concerning you.</li>
                          </ul>
                          <p><br/>
                              We may deny your request to exercise any of the rights described above if doing so is reasonably necessary for us (or our affiliates or processors) to:
                          </p>
                          <ul className="list-style-type-disc">
                              <li>Prevent, detect, protect against, or respond to security incidents, identity theft, fraud, harassment, malicious or deceptive activities, or any activity that is illegal under applicable law;</li>
                              <li>Preserve the integrity or security of systems; or</li>
                              <li>Investigate, report, or prosecute those responsible for any such action that is illegal under applicable law.</li>
                          </ul>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="EXCERCISING_YOUR_RIGHTS">EXERCISING YOUR RIGHTS</h3>
                          <p><em>How to Submit a Request to Exercise Your Rights</em></p>
                          <p>To exercise your rights, please contact us using our <span className="fw-700"><a  className="text-dark text-decoration-none" href="https://privacyportal-ch.onetrust.com/webform/9eb64978-23c0-4924-97a4-03eb6aab1106/6a2277d2-5ebb-4850-8057-c9c4c325dafc">webform</a></span>, or contact us by phone at 1-866-735-4137 extension 3, or by email or mail at the addresses in the “<a  className="text-dark text-decoration-none" href="#CONTACT_INFORMATION"><span className="fw-700">Contact Information</span></a>” section below. If you choose to email us, you must put the statement “Your Privacy Rights” in the subject field. We may take steps to verify your identity before responding to your request by asking you a series of questions about your previous interactions with us.</p>
                          <p><em>Appealing Our Decision About Your Rights</em></p>
                          <p>We may deny certain requests and will explain why your request was denied and how to appeal our decision, as required by applicable law. After receiving our explanation, if you would like to appeal our decision, you may do so as further detailed in our communication back to you. For questions, you may also contact us by calling or emailing us at the contact information below. </p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4">CHANGES TO THE GALDERMA CONSUMER HEALTH DATA PRIVACY POLICY</h3>
                          <p>We reserve the right, at our sole discretion, to change, modify, add, remove, or otherwise revise portions of this Policy at any time. When we do, we will post the change(s) on the Platform.  Your continued use of the Services or Platform following the posting of changes to this Policy means you accept these changes.</p>
                          <h3 className="font-20 fw-500 lh-38 text-left my-4" id="CONTACT_INFORMATION">CONTACT INFORMATION</h3>
                          <p>If you have any questions or concerns about this Policy or the practices described herein, you may contact us at <span className="fw-700"><a  className="text-dark text-decoration-none" href="mailto:usprivacy@galderma.com">usprivacy@galderma.com</a></span>, by phone at 1-866-735-4137 extension 3, or by mail to:</p>
                          <p>Galderma Laboratories, L.P.</p>
                          <p>2001 Ross Avenue, Suite 1600</p>
                          <p>Dallas, TX 75201</p>
                          <p>Attention: Chief Privacy Officer</p>
                        </li>
                    </ol>
            </div>
        </div>
      </div>
    </section>
  </>);
};

export default PrivacyPolicy;
